import ApiError, { ApiErrorParams } from './ApiError';

export type AppError = ApiError | Error;

export type SerializedAppError =
  | ApiErrorParams
  | { message: string; stack?: string };

export enum ApiErrorCodes {
  ORCHARD_EMPLOYEE_REQUIRES_ACCOUNT = 'ORCHARD_ADMIN_REQUIRES_ACCOUNT',
}

// https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2.1
export type AuthorizationErrorCode =
  | 'invalid_request'
  | 'unauthorized_client'
  | 'access_denied'
  | 'unsupported_response_type'
  | 'invalid_scope'
  | 'server_error'
  | 'temporarily_unavailable'
  // This error code is not part of the OAuth2 spec and coming from Spotify native app
  // when user cancels the auth flow by clicking the cancel button in in-app popup
  | 'user_canceled';
